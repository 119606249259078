body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 1.33;
  width: 100%;
  height: 100%;
  &.fullscreen-loading {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
}

.container {
  display: flex;
  max-width: 1140px !important;
  width: 100% !important;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 100%;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .fv__ui-mobile,
  .fv__ui-mobile body {
    overflow: visible;
  }
}

apple-pay-button {
  --apple-pay-button-width: 100%;
}
